<template>
  <v-dialog
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    v-if="
      trackingData.status &&
      trackingData.status.etaTimestampMin &&
      trackingData.status.etaTimestampMax &&
      pricing
    "
    :persistent="true"
    :overlay="true"
    content-class="ma-5"
    max-width="500"
  >
    <v-card
      v-if="step === steps.timeSelection"
      width="100%"
      class="card_dialog v-col-12"
    >
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler()"
      />
      <v-card-title class="dialog-title">
        {{ $t('customization.deliveryPeriod.timeSelection.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        <div
          v-html="$t('customization.deliveryPeriod.timeSelection.description')"
        />
      </v-card-text>
      <p v-if="!deliveryPeriodSlots.length">
        {{ $t('customization.deliveryPeriod.timeSelection.none') }}
      </p>
      <v-btn
        v-for="slot in deliveryPeriodSlots"
        :key="slot.asString"
        :block="true"
        v-on:click="onSelectTimeSlot(slot)"
        :class="`info mb-2 ${slot.isLockIn && 'lockIn'}`"
        :data-label="$t('customization.deliveryPeriod.timeSelection.lockIn')"
        >{{ slot.asString }} ({{ slot.price }})
      </v-btn>
      <AlertComponent :message="message" />
    </v-card>

    <v-card
      v-if="step === steps.email"
      width="100%"
      class="card_dialog v-col-12"
    >
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler()"
      />
      <v-card-title class="dialog-title">
        {{ $t('customization.deliveryPeriod.email.header') }}
        {{ selectedSlot.price }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        {{ $t('customization.deliveryPeriod.payment.description') }}
        {{ selectedSlot.asString }}
      </v-card-text>
      <v-card-text class="px-0 py-2"></v-card-text>
      <v-form :ref="deliverPeriodForm" v-model="valid">
        <p>{{ $t('customization.deliveryPeriod.email.labelText') }}</p>
        <v-text-field
          variant="outlined"
          required
          :rules="emailRules"
          v-model="email"
        ></v-text-field>
        <AlertComponent :message="message" />
        <div>
          <v-btn
            :block="true"
            type="submit"
            v-on:click.prevent="onSelectEmail"
            :disabled="!valid"
            class="bg-primary"
            >{{ $t('generic.save') }}
          </v-btn>
        </div>
      </v-form>
    </v-card>

    <v-card
      v-if="step === steps.payment"
      width="100%"
      class="card_dialog v-col-12"
    >
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler()"
      />
      <v-card-title class="dialog-title">
        {{ $t('customization.deliveryPeriod.payment.header') }}
        {{ selectedSlot.price }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        <div v-html="$t('customization.deliveryPeriod.payment.description')" />
      </v-card-text>
      <v-card-text class="px-0 py-2">
        <p>
          {{ $t('customization.deliveryPeriod.payment.today') }}
          {{ selectedSlot.asString }}.
          {{ $t('customization.deliveryPeriod.payment.payWith') }}:
        </p>
        <v-btn
          v-for="(method, index) in paymentMethods"
          :key="method.name"
          :block="true"
          v-on:click="onSelectPaymentMethod(method)"
          :class="`${index === 0 ? 'footerBackground white--text' : ''} mb-2 `"
          variant="outlined"
        >
          <img class="ml-2" :src="method.icon" :alt="method.name" />
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card
      v-if="step === steps.paymentProcessing"
      width="100%"
      class="card_dialog v-col-12 text-center"
    >
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler()"
      />
      <v-card-title class="dialog-title pa-0 text-center">
        {{ $t('customization.deliveryPeriod.paymentProcessing.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        {{ $t('customization.deliveryPeriod.paymentProcessing.description') }}
      </v-card-text>
      <v-card-text>
        <img class="spinner" :src="spinner" alt="Loading" />
      </v-card-text>
    </v-card>

    <v-card
      v-if="step === steps.failure"
      width="100%"
      class="card_dialog v-col-12 text-center"
    >
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler()"
      />
      <v-card-title class="dialog-title pa-0 text-center">
        {{ $t('customization.deliveryPeriod.failure.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        {{ $t('customization.deliveryPeriod.failure.description') }}<br /><br />
        <b>{{ $t('customization.deliveryPeriod.failure.description2') }}</b>
      </v-card-text>
      <div>
        <v-form>
          <v-btn
            :block="true"
            type="submit"
            v-on:click.prevent="dialogHandler()"
            class="bg-primary"
            >{{ $t('generic.okay') }}
          </v-btn>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  ACTION_CUSTOMIZED_DELIVERY,
  ACTION_TYPE_ERROR,
  ACTION_TYPE_INFO
} from '@/store/constants';
import AlertComponent from '@/components/AlertComponent';
import moment from 'moment';
import { roundMomentJs, roundToMinutesInTimeRange } from '@/services/time';
import applePayIcon from '/icon_apple_pay.svg';
import mastercardIcon from '/icon_mastercard.svg';
import paypalIcon from '/icon_paypal.svg';
import idealIcon from '/icon_ideal.svg';
import googlePayIcon from '/icon_google_pay.svg';
import spinner from '/spinner.svg';
import { sendAnalytics as _sendAnalytics } from '@/services/analytics';
import dialogs from '@/components/dialogs/dialogs.js';
import { TENANT_IDS } from '@/config/tenants.js';

const pricingMap = {
  [TENANT_IDS.GLS_DE]: {
    lockIn: 1.99,
    afterTime: 1.99,
    beforeTime: 1.99,
    businessTime: 1.99
  }
};

export default {
  name: 'TimeFrameSelectionDialog', // aka DeliveryPeriod
  components: {
    AlertComponent
  },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      steps: {
        timeSelection: 'timeSelection',
        email: 'email',
        payment: 'payment',
        paymentProcessing: 'paymentProcessing',
        failure: 'failure'
      },
      step: 'timeSelection',
      selectedSlot: null,
      deliveryPeriodData: {},
      deliverPeriodForm: null,
      email: null,
      emailRules: [
        v =>
          !!v ||
          this.$t('customization.deliveryPeriod.email.rules.emailRequired')
      ],
      loading: false,
      success: false,
      valid: true,
      spinner
    };
  },
  computed: {
    ...mapState(['message', 'trackingData']),
    pricing() {
      return pricingMap[this.trackingData?.tenantId];
    },
    timeFrame() {
      const { etaTimestampMin: from, etaTimestampMax: to } =
        this.trackingData?.status || {};
      if (!from || !to) return false;

      return {
        from: moment(from),
        to: moment(to)
      };
    },
    deliveryPeriodSlots() {
      if (moment().get('hours') >= 16) return [];
      const today = moment().format('YYYY-MM-DD');
      /*
      const times = (
        moment().get('hours') > 12
          ? ['10:00', '12:00', '14:00', '16:00', '18:00']
          : ['10:00', '14:00', '18:00']
      ) // had to many options, so just remove some based on current time
        .map(t => moment(`${today} ${t}`));

      const { from, to } = this.timeFrame;
      const nowish = moment().add(1.2, 'hours');
      const min = moment(from).subtract(2, 'hours');
      const beforeTimes = times
        .filter(t => t.isAfter(nowish) && t.isSameOrBefore(min))
        .map(t => ({ from: t, price: this.pricing.beforeTime }));
      const max = moment(to).add(2, 'hours');
      const afterTimes = times
        .filter(t => !!t.isSameOrAfter(max))
        .map(t => ({ from: t, price: this.pricing.afterTime }));
     */
      const addToTime = data => ({
        ...data,
        to: moment(data.from).add(1, 'hour')
      });
      const businessTime1 = addToTime({
        from: moment(`${today} ${'19:00'}`),
        price: this.pricing.businessTime
      });
      const businessTime2 = addToTime({
        from: moment(`${today} ${'20:00'}`),
        price: this.pricing.businessTime
      });

      const timeFrames = [
        // this.getLockInSlot(), simply disabled for now as it only runs in NL
        // ...beforeTimes.map(addToTime),
        // ...afterTimes.map(addToTime),
        businessTime1,
        businessTime2
      ].map(({ from, to, ...props }) => ({
        ...props,
        asString: `${from.format('HH:mm')} - ${to.format('HH:mm')}`,
        price: `€ ${props.price}`
      }));

      if (this.dialog && this.step === this.steps.timeSelection) {
        this.sendAnalytics('time_frame_options', {
          timeFrameAndPriceList: timeFrames.map(t => ({
            time: t.asString,
            price: t.price
          }))
        });
      }

      return timeFrames;
    },
    paymentMethods() {
      return [
        { name: 'Apple Pay', icon: applePayIcon },
        { name: 'mastercard', icon: mastercardIcon },
        { name: 'Paypal', icon: paypalIcon },
        { name: 'Ideal', icon: idealIcon },
        { name: 'Google Pay', icon: googlePayIcon }
      ];
    },
    dialog: {
      get() {
        return this.open;
      }
    }
  },
  methods: {
    ...mapActions([
      'updateCustomizedDeliveryPeriod',
      'sendTimeFrameFailure',
      'toggleDialog'
    ]),
    getLockInSlot() {
      const { from, to } = this.timeFrame;
      const fromRounded = roundMomentJs(
        from.subtract(5, 'minutes'), // subtract 5 minutes so 11:35 - 12:35 would stick to 11:30 - 12:30 instead of 12:00 - 13:00
        moment.duration(30, 'minutes'),
        'ceil'
      );
      const diff = roundToMinutesInTimeRange(
        to.diff(from, 'minutes') - 1,
        30,
        30,
        240
      );
      const toRounded = moment(fromRounded).add(diff, 'minutes');
      return {
        from: fromRounded,
        to: toRounded,
        isLockIn: true,
        price: this.pricing.lockIn
      };
    },
    onSelectTimeSlot(slot) {
      this.selectedSlot = slot;
      this.step = this.steps.email;

      this.sendAnalytics('time_frame_selection', {
        selectedTimeFrame: slot.asString,
        selectedPrice: slot.price
      });
    },
    sendAnalytics(eventName, payload) {
      _sendAnalytics(this.trackingData, 'time_frame', eventName, payload);
    },
    onSelectEmail() {
      this.sendAnalytics('entered_email', {});
      this.step = this.steps.payment;
    },
    onSelectPaymentMethod(method) {
      this.sendAnalytics('payment_type_selection', { method: method.name });

      this.step = this.steps.paymentProcessing;

      this.sendTimeFrameFailure({
        body: { email: this.email },
        tenantId: this.$rttI18n.tenantId,
        postcode: this.trackingData.toAddress.postcode,
        trackingId: this.trackingData.trackingId
      });

      setTimeout(() => {
        this.step = this.steps.failure;
        this.updateCustomizedDeliveryPeriod({
          trackingId: this.trackingData.trackingId,
          deliveryPeriod: this.selectedSlot.asString
        });
      }, 4000);
    },
    dialogHandler(dialog = dialogs.DELIVERY_PERIOD) {
      this.toggleDialog(dialog);
    }
  },
  watch: {
    message(message) {
      if (
        message.msg === ACTION_CUSTOMIZED_DELIVERY &&
        message.type === ACTION_TYPE_INFO
      ) {
        this.loading = false;
        this.success = true;
      }
      if (message.type === ACTION_TYPE_ERROR) {
        this.loading = false;
        this.success = false;
      }
    }
  }
};
</script>

<style scoped>
.footerBackground {
  background-color: rgb(var(--v-theme-primary));
}

.lockIn {
  padding-top: 12px !important;
}

.lockIn::after {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  position: absolute;
  content: attr(data-label);
  background-color: rgb(var(--v-theme-primary));
  left: 0;
  font-size: 0.6em;
  font-weight: normal;
  font-family: 'Newson', sans-serif !important;
  right: 0;
  min-width: 100px;
  height: 46px;
  top: 0;
  box-shadow: none;
  color: #090909;
  clip-path: polygon(0 0, 100% 0%, 100% 12px, 0 12px);
}

.spinner {
  animation: spinner 0.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
