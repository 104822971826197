<template>
  <header>
    <div
      class="v-container d-flex align-center justify-space-between py-2 py-md-4"
    >
      <ThemeElementSlot name="Logo" />
      <LanguageSelector />
    </div>
    <ThemeElementSlot name="Subheader" />
  </header>
</template>

<script>
import LanguageSelector from '@/components/LocaleSelector.vue';
import ThemeElementSlot from '@/components/ThemeElementSlot';

export default {
  name: 'RttHeaderComponent',
  components: {
    LanguageSelector,
    ThemeElementSlot
  }
};
</script>

<style scoped>
header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: rgb(var(--v-theme-headerBackground));
}
</style>
