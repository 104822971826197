<template>
  <v-select
    aria-label="locale selection"
    density="compact"
    variant="solo"
    :flat="true"
    :hide-details="true"
    bg-color="rgba(0, 0, 0, 0)"
    class="lang-selection"
    :menu-props="{
      contentClass: 'lang-menu'
    }"
    single-line
    :items="locales"
    :model-value="locale"
    item-title="name"
    item-value="code"
  >
    <template v-slot:selection="{ item }">
      <v-tooltip :text="$t('generic.selectLanguage')">
        <template v-slot:activator="{ props }">
          <span v-bind="props" class="selected-locale">{{ item.title }}</span>
        </template>
      </v-tooltip>
    </template>
    <template v-slot:item="{ item, props }">
      <v-list-item
        v-bind="props"
        :class="{ selected: props['aria-selected'] === 'true' }"
        :disabled="props['aria-selected'] === 'true'"
        v-on:click="selectLocale(item.raw.code)"
      >
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import { mapState } from 'vuex';
import locales from '@/config/locales.js';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      locale: this.$rttI18n.locale,
      locales: this.getLocales(this.$rttI18n.locale)
    };
  },
  computed: {
    ...mapState(['themeId'])
  },
  watch: {
    '$rttI18n.locale'(newLocale) {
      if (newLocale !== this.locale) {
        this.locale = newLocale;
        this.locales = this.getLocales(newLocale).slice(0);
      }
    }
  },
  methods: {
    getLocales(locale) {
      return Object.values(locales).sort((a, b) => {
        if (a.code === locale) return -1;
        if (b.code === locale) return 1;
        return a.name.localeCompare(b.name);
      });
    },
    selectLocale(newLocale) {
      if (this.$route.params.countryOrTenantId) {
        this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params, locale: newLocale }
        });
      } else {
        this.$localePlugin.updateLocale(newLocale);
      }
    }
  }
};
</script>

<style>
.lang-menu .v-list-item-title {
  font-size: 0.875rem;
}

.lang-menu .v-list-item--active {
  color: rgba(11, 16, 66, 0.4);
}

.lang-selection {
  flex-grow: 0 !important;
}

.lang-selection .v-input__control {
  min-height: auto !important;
}

.lang-selection .v-input__control .v-input__slot {
  background-color: transparent !important;
  padding: 0 0 !important;
}

.lang-selection .v-input__control .v-select__selections input {
  width: 0;
}

.lang-selection .v-icon {
  color: rgb(var(--v-theme-headerColor));
}

.selected-locale {
  padding-top: 3px;
  color: rgb(var(--v-theme-headerColor));
}
</style>
