import moment from 'moment';

// usage: roundMomentJs(date, moment.duration(30, 'minutes'), 'ceil')
export function roundMomentJs(date, duration, method) {
  const d = moment(Math[method](+date / +duration) * +duration);
  d.tz(date.tz());
  return d;
}

export function roundToMinutesInTimeRange(time, minutesToRound, min, max) {
  return Math.max(
    min,
    Math.min(max, Math.floor(time / minutesToRound) * minutesToRound)
  );
}
