<template>
  <svg
    height="32"
    viewBox="0 0 92 32"
    width="92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m86.5 31.4c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1c2.9 0 5.1 2.3 5.1 5.1s-2.2 5.1-5.1 5.1z"
      :fill="dotColor"
    />
    <g :fill="glsColor">
      <path
        d="m15.1 15v6.6h5.6c-.9 1.3-2.7 2-3.9 2q-6.3 0-6.3-7.2c0-5.3 2.8-8 8.2-8 2.2 0 5.1.6 8.1 1.5v-8c-.9-.3-2.2-.6-3.9-.9q-2.7-.4-4.7-.4-8.1 0-12.9 4.4c-3.2 2.9-4.9 6.8-4.9 11.8q0 6.8 3.8 10.7 3.8 4 10.2 4c4.2 0 8.2-2.2 9.4-5v4.5h6.2v-16z"
      />
      <path d="m33 31v-29.9h9.6v22.1h10.5v7.8z" />
      <path
        d="m56.1 30.2v-8.1q2.2.6 5 1.1 2.8.5 4.9.5c2.3 0 3.5-.5 3.5-1.6 0-.6-.3-1-2.1-1.3l-3.3-.8c-5.7-1.3-8.4-4.2-8.4-9q0-4.9 3.3-7.7c2.2-1.8 5.3-2.7 9.2-2.7 2.7 0 6.8.5 9.3 1.2v7.7q-1.9-.5-4.7-.9-2.8-.4-4.5-.4c-2 0-3.2.6-3.2 1.6 0 .6.6 1 1.7 1.3l3.9.9c5.7 1.4 8.3 4.3 8.3 9.3q0 4.7-3.5 7.4-3.4 2.8-9.5 2.8c-3.8 0-7.7-.6-9.9-1.3z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'GlsLogoSvg',
  props: {
    glsColor: {
      type: String,
      default: '#061ab1'
    },
    dotColor: {
      type: String,
      default: '#ffd100'
    }
  }
};
</script>
