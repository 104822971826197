<template>
  <footer class="mt-16 mt-md-20 d-flex flex-column position-relative">
    <div
      class="powered-by v-container d-flex flex-column-reverse flex-md-row py-6 ga-6 ga-md-3 justify-md-space-between align-center"
    >
      <div v-if="!config.disablePoweredBy" class="d-flex gap-3">
        <span>powered by</span>
        <a v-if="$rttI18n.isGlsItSelected" href="/" title="Bettermile">
          <BettermileLogoSvg class="logo bettermile-logo" />
        </a>
        <a v-else href="/" title="GLS">
          <GlsLogoSvg
            class="logo gls-logo"
            :glsColor="$vuetify.theme.current.colors.footerGlsIconColor"
          />
        </a>
      </div>
      <section class="footer-container">
        <router-link
          v-if="!config.disableImprintLink"
          :to="imprintLink"
          @click="sendClickStatAnalytics('imprintLink')"
        >
          {{ $t('imprint.name') }}
        </router-link>

        <router-link
          v-if="privacyContentExist"
          :to="internalPrivacyLink"
          @click="sendClickStatAnalytics('internalPrivacyLink')"
        >
          {{ $t('privacy.name') }}
        </router-link>

        <a
          v-else
          :href="privacyLink"
          @click="sendClickStatAnalytics('privacyLink')"
          target="_blank"
          >{{ $t('privacy.name') }}</a
        >

        <a
          v-if="externalContactLink"
          :href="externalContactLink"
          @click="sendClickStatAnalytics('externalContactLink')"
          target="_blank"
          >{{ $t('generic.contact') }}</a
        >

        <router-link
          v-else-if="!config.disableContactLink"
          @click="sendClickStatAnalytics('contactLink')"
          :to="contactLink"
        >
          {{
            $rttI18n.tenantSpecificWithFallback(
              'contact.label',
              'generic.contact'
            )
          }}
        </router-link>
        <a
          v-if="$rttI18n.isGlsItSelected"
          :href="publicPath(`/themes/${themeId}/accessibility_report.pdf`)"
          @click="sendClickStatAnalytics('accessibilityReport')"
          target="_blank"
        >
          {{ $t('accessibility.label') }}
        </a>
      </section>
    </div>
    <a
      :href="faqLink"
      @click="sendClickStatAnalytics('faqLink')"
      class="faq-link"
      target="_blank"
    >
      <img
        alt="GLS Group FAQ"
        :src="publicPath(`/themes/${themeId}/icon_gls_faq.svg`)"
      />
    </a>
  </footer>
</template>

<script>
import { configGetter } from '@/config/configGetter';
import { mapGetters, mapState } from 'vuex';
import publicPath from '@/util/publicPath';
import { sendAnalytics } from '@/services/analytics.js';
import BettermileLogoSvg from '@/components/bettermile/BettermileLogoSvg.vue';
import GlsLogoSvg from '@/components/gls/GlsLogoSvg.vue';

export default {
  name: 'RttFooterComponent',
  methods: {
    sendClickStatAnalytics(name) {
      sendAnalytics(this.trackingData, 'clicksStats', name);
    }
  },
  components: {
    GlsLogoSvg,
    BettermileLogoSvg
  },
  data() {
    return {
      publicPath
    };
  },
  computed: {
    ...mapGetters(['getTrackingState']),
    ...mapState(['themeId', 'trackingData', 'isBelowMobileBreakpoint']),
    config() {
      return configGetter(this.$rttI18n.tenantId, this.themeId);
    },
    externalContactLink() {
      return this.$rttI18n.tenantSpecificContent('footer.contactLink');
    },
    privacyContentExist() {
      return this.$rttI18n.tenantSpecificContentExists('privacy.content');
    },
    contactLink() {
      const routeName = this.$t(`languageSpecificRoutes.contact`);
      return `/${this.$rttI18n.tenantId ?? 'DE'}/${
        this.$rttI18n.locale
      }/${routeName}`;
    },
    faqLink() {
      return this.$rttI18n.tenantSpecificContentOrGeneric('faqLink');
    },
    imprintLink() {
      const routeName = this.$t(`languageSpecificRoutes.imprint`);
      if (this.$route.params.countryOrTenantId) {
        return `/${this.$route.params.countryOrTenantId}/${this.$rttI18n.locale}/${routeName}`;
      }
      return `/DE/${this.$rttI18n.locale}/imprint`;
    },
    privacyLink() {
      return this.$rttI18n.tenantSpecificWithFallback(
        'privacy.link',
        'glsGroup.privacy.link'
      );
    },
    internalPrivacyLink() {
      const routeName = this.$t(`languageSpecificRoutes.privacy`);
      if (this.$route.params.countryOrTenantId) {
        return `/${this.$route.params.countryOrTenantId}/${this.$rttI18n.locale}/${routeName}`;
      }
      return `/DE/${this.$rttI18n.locale}/privacy`;
    }
  }
};
</script>

<style scoped>
.faq-link {
  top: -58px;
  position: absolute;
  right: 12px;
}

.faq-link > img {
  height: 50px;
}

footer {
  background-color: rgb(var(--v-theme-footerBackground));
  color: rgb(var(--v-theme-footerColor));
}

.footer-container {
  text-align: center;
}

.footer-container a,
.footer-container a:visited {
  text-decoration: none;
  padding: 0 16px;
  display: inline-block;
  color: rgb(var(--v-theme-footerColor));
  border-right: 1px solid rgb(var(--v-theme-footerColor));
}

.footer-container a:last-child {
  border-right: none;
  padding-right: 0;
}

.footer-container a:first-child {
  padding-left: 0;
}

.powered-by {
  color: rgba(var(--v-theme-footerColor), 0.7);
  font-size: 14px;
  line-height: 22px;
}
.logo {
  height: 17px;
  width: auto;
}

.bettermile-logo {
  fill: rgb(var(--v-theme-footerColor));
}
</style>
