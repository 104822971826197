<template>
  <v-combobox
    class="phone-country-prefix"
    bg-color="rgb(var(--v-theme-primary))"
    v-model="prefix"
    :items="prefixes"
    item-value="value"
    item-title="text"
    aria-label="phone prefix"
    single-line
    variant="outlined"
    :placeholder="
      $t('customization.notificationSubscription.phoneNumberPrefix.placeholder')
    "
    required
    v-on:update:menu="$emit('setPrefix', prefix)"
  />
</template>

<script>
import { phoneCodePrefixes } from '@/services/phoneCodes';

export default {
  name: 'PhoneNumberCountryPrefixComponent',
  data() {
    return {
      prefix: null,
      prefixes: phoneCodePrefixes
    };
  },
  mounted() {
    this.prefix = this.prefixes.find(prefix => {
      if (prefix.text.includes(this.$rttI18n.country)) {
        return prefix.text;
      }
    });
    this.$emit('setPrefix', this.prefix);
  }
};
</script>

<style>
.phone-country-prefix.theme--light.v-input input {
  color: #000 !important;
}
.phone-country-prefix.theme--light.v-input input::placeholder {
  color: #333 !important;
}
</style>
<style scoped>
.phone-country-prefix.v-input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
</style>
