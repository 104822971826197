import { createApp } from 'vue';
import { createStore } from '@/store';
import App from '@/pages/index/App';
import { init } from '@/pages/init.js';

const app = createApp(App);

const store = createStore();
store.$containerId = 'rtt';

const urlPath = window.location.hash.substring(1);
init(urlPath, app, store, false);
