<template>
  <v-app light class="rttApp" :class="getThemeId">
    <div v-if="getReviewAppId" id="reviewAppBanner">
      Review App {{ getReviewAppId }}
    </div>
    <RttHeader />
    <DialogHandler />
    <v-main>
      <ThemeElementSlot name="Background" />
      <v-container>
        <AlertComponent v-if="!message.modal" :message="message" />
        <router-view></router-view>
      </v-container>
      <ThemeElementSlot name="SubContent" />
    </v-main>
    <RttFooter />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RttHeader from '@/components/RttHeader.vue';
import RttFooter from '@/components/RttFooter.vue';
import AlertComponent from '@/components/AlertComponent.vue';
import ThemeElementSlot from '@/components/ThemeElementSlot.vue';
import { setThemeName } from '@/plugins/vuetify.js';
import loadConfigByThemeId, {
  defaultThemeName,
  getThemeIdFromStateAndRoute
} from '@/config/themes.js';
import DialogHandler from '@/components/dialogs/DialogHandler.vue';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@/store/constants.js';

export default {
  name: 'App',
  components: {
    DialogHandler,
    AlertComponent,
    RttFooter,
    RttHeader,
    ThemeElementSlot
  },
  data() {
    return {
      isResizing: false,
      resizeStartTime: null,
      resizingDelta: 0
    };
  },
  computed: {
    ...mapGetters(['getReviewAppId', 'getThemeId']),
    ...mapState(['message', 'trackingData']),
    themeConfig() {
      return loadConfigByThemeId(this.getThemeId);
    }
  },
  methods: {
    ...mapActions([
      'setThemeId',
      'setIsBelowMobileBreakpoint',
      'setIsBelowTabletBreakpoint'
    ]),
    updateTheme() {
      const themeId =
        getThemeIdFromStateAndRoute(this.$store.state, this.$route) ||
        defaultThemeName();
      setThemeName(this.$vuetify, themeId);
      this.setThemeId(themeId);
    },
    resizeHandler() {
      this.resizeStartTime = new Date();
      if (!this.isResizing) {
        this.isResizing = true;
        setTimeout(this.resizeEnd, this.resizingDelta);
      }
    },
    resizeEnd() {
      if (new Date() - this.resizeStartTime < this.resizingDelta) {
        setTimeout(this.resizeEnd, this.resizingDelta);
      } else {
        this.isResizing = false;
        const isBelowMobileBreakpoint = window.innerWidth < MOBILE_BREAKPOINT;
        this.setIsBelowMobileBreakpoint(isBelowMobileBreakpoint);
        const isBelowTabletBreakpoint = window.innerWidth < TABLET_BREAKPOINT;
        this.setIsBelowTabletBreakpoint(isBelowTabletBreakpoint);
      }
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  watch: {
    '$route.params.countryOrTenantId'(newCountry, oldCountry) {
      this.updateTheme();
    },
    trackingData(newTrackingData) {
      this.updateTheme();
    }
  }
};
</script>

<style>
.rttApp {
  /* put styles into overrides.scss which are generic for index and widget */
  #reviewAppBanner {
    background: #e0e2f1;
    color: #061ab1;
    padding: 1em;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}
</style>
