<template>
  <v-dialog
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    :overlay="true"
    content-class="ma-5"
    max-width="500"
  >
    <v-card v-if="!success" width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.PUSH_NOTIFICATION_SUBSCRIPTION)"
      />
      <v-card-title class="dialog-title">
        {{ $t('pushNotification.header') }}
      </v-card-title>
      <v-card-text
        v-if="!supportsPushNotification"
        class="px-0 py-0 text-error"
      >
        {{ $t('pushNotification.browserUnsupported') }}
        <a href="https://caniuse.com/?search=PushManager"
          >Caniuse.com > PushManager</a
        >
      </v-card-text>
      <v-card-text v-if="isIncognitoMode" class="px-0 py-0 text-error">
        {{ $t('pushNotification.incognitoMode') }}
      </v-card-text>
      <v-card-text class="px-0 py-2"
        ><div v-html="$t('pushNotification.description')"
      /></v-card-text>
      <v-form v-model="valid">
        <v-select
          v-model="selectedMinutesBeforeDelivery"
          :items="availableMinutesBeforeDelivery"
          :rules="[v => !!v || 'Item is required']"
          :label="$t('pushNotification.placeholder')"
          required
        ></v-select>
        <v-btn
          :block="true"
          type="submit"
          v-on:click.prevent="addNotificationSubscriptionFromDialog"
          :disabled="!valid || !selectedMinutesBeforeDelivery"
          :loading="loading"
          class="bg-info"
          color="info"
          >{{ $t('pushNotification.submit') }}</v-btn
        >
      </v-form>
    </v-card>

    <v-card v-if="success" width="100%" class="v-col-12 px-4 py-5 text-center">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.PUSH_NOTIFICATION_SUBSCRIPTION)"
      />
      <v-card-title class="dialog-title pa-0 text-center">
        {{ $t('pushNotification.success.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        {{
          $t(
            'pushNotification.success.description',
            trackingData.pushNotification
          )
        }}
      </v-card-text>
      <div>
        <v-form>
          <v-btn
            type="submit"
            v-on:click.prevent="
              dialogHandler(dialogs.PUSH_NOTIFICATION_SUBSCRIPTION)
            "
            class="bg-primary"
            >{{ $t('generic.okay') }}</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  ACTION_PUSH_NOTIFICATION_SUBSCRIPTION,
  ACTION_TYPE_ERROR,
  ACTION_TYPE_INFO
} from '@/store/constants';
import {
  NotificationPermissionError,
  supportsPushNotification
} from '@/services/pushNotification';
import { sendAnalytics } from '@/services/analytics';
import { detectIncognito } from 'detectincognitojs';
import dialogs from '@/components/dialogs/dialogs.js';

export default {
  name: 'NotificationSubscriptionDialog',
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      supportsPushNotification,
      availableMinutesBeforeDelivery: [10, 20, 30, 40, 50],
      selectedMinutesBeforeDelivery: 30,
      loading: false,
      success: false,
      valid: false,
      isIncognitoMode: undefined,
      dialogs
    };
  },
  computed: {
    ...mapState(['message', 'trackingData']),
    dialog: {
      get() {
        return this.open;
      }
    }
  },
  methods: {
    ...mapActions(['addPushNotification', 'toggleDialog']),
    getPrivacyLink() {
      return this.$rttI18n.tenantSpecificWithFallback(
        'privacy.link',
        'glsGroup.privacy.link'
      );
    },
    async addNotificationSubscriptionFromDialog() {
      this.loading = true;
      try {
        await this.addPushNotification({
          tenantId: this.$rttI18n.tenantId,
          postcode: this.trackingData.toAddress.postcode,
          trackingId: this.trackingData.trackingId,
          minutesBeforeDelivery: this.selectedMinutesBeforeDelivery,
          language: this.$rttI18n.locale
        });
      } catch (err) {
        let allowContact = null;
        if (err instanceof NotificationPermissionError) {
          allowContact = window.confirm(
            this.$t(`pushNotification.permissionError`).toString()
          );
        }

        if (this.isIncognitoMode) return;

        sendAnalytics(this.trackingData, 'pushNotification', 'error', {
          message: err.message,
          timing: err.timing,
          userAgent: navigator.userAgent || navigator.vendor || window.opera,
          allowContact: `${allowContact} ${
            allowContact
              ? this.$t(`pushNotification.permissionError`).toString()
              : ''
          }`
        });
      }
      this.loading = false;
      sendAnalytics(this.trackingData, 'pushNotification', 'subscribe', {
        minutesBeforeDelivery: this.selectedMinutesBeforeDelivery
      });
    },
    dialogHandler(dialog) {
      this.resetDialog();
      this.toggleDialog(dialog);
    },
    resetDialog() {
      this.selectedMinutesBeforeDelivery = 30;
    }
  },
  watch: {
    message() {
      if (
        this.message.msg === ACTION_PUSH_NOTIFICATION_SUBSCRIPTION &&
        this.message.type === ACTION_TYPE_INFO
      ) {
        this.success = true;
      }
      if (this.message.type === ACTION_TYPE_ERROR) {
        this.success = false;
      }
    },
    open() {
      if (this.open) {
        this.success = false;
        const minutesBeforeDelivery =
          this.trackingData.smsNotification?.minutesBeforeDelivery;
        if (minutesBeforeDelivery) {
          this.selectedMinutesBeforeDelivery = minutesBeforeDelivery;
        }
      }
    }
  },
  created() {
    detectIncognito().then(result => {
      this.isIncognitoMode = result.isPrivate;
    });
  }
};
</script>
<style scoped></style>
