<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { setThemeName } from '@/plugins/vuetify.js';
import loadConfigByThemeId, {
  defaultThemeName,
  getThemeIdFromStateAndRoute
} from '@/config/themes.js';
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from '@/store/constants.js';
import {
  mapActions as mapActionsPinia,
  mapState as mapStatePinia
} from 'pinia';
import { useResponsiveBreakpointsStore } from '@/stores/responsiveBreakpoints.js';
import { useThemeStore } from '@/stores/theme.js';
import { useTrackingDataStore } from '@/stores/trackingData.js';

export default {
  name: 'App',
  data() {
    return {
      isResizing: false,
      resizeStartTime: null,
      resizingDelta: 0
    };
  },
  computed: {
    ...mapGetters(['getReviewAppId']),
    ...mapState(['message']),
    ...mapStatePinia(useThemeStore, ['themeId']),
    themeConfig() {
      return loadConfigByThemeId(this.themeId);
    }
  },
  methods: {
    ...mapActionsPinia(useThemeStore, ['setThemeId']),
    ...mapActionsPinia(useResponsiveBreakpointsStore, [
      'setIsBelowMobileBreakpoint',
      'setIsBelowTabletBreakpoint'
    ]),
    updateTheme() {
      const themeId =
        getThemeIdFromStateAndRoute(this.$store.state, this.$route) ||
        defaultThemeName();
      setThemeName(this.$vuetify, themeId);
      this.setThemeId(themeId);
    },
    resizeHandler() {
      this.resizeStartTime = new Date();
      if (!this.isResizing) {
        this.isResizing = true;
        setTimeout(this.resizeEnd, this.resizingDelta);
      }
    },
    resizeEnd() {
      if (new Date() - this.resizeStartTime < this.resizingDelta) {
        setTimeout(this.resizeEnd, this.resizingDelta);
      } else {
        this.isResizing = false;
        const isBelowMobileBreakpoint = window.innerWidth < MOBILE_BREAKPOINT;
        this.setIsBelowMobileBreakpoint(isBelowMobileBreakpoint);
        const isBelowTabletBreakpoint = window.innerWidth < TABLET_BREAKPOINT;
        this.setIsBelowTabletBreakpoint(isBelowTabletBreakpoint);
      }
    }
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted() {
    const trackingDataStore = useTrackingDataStore();
    trackingDataStore.$subscribe((mutation, state) => {
      this.updateTheme();
    });
  },
  unmounted() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  watch: {
    '$route.params.countryOrTenantId'(newCountry, oldCountry) {
      this.updateTheme();
    }
  }
};
</script>

<style></style>
