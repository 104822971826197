/**
 * mapNotNull
 * A combination of filter and map, ability to exclude some elements and also
 * transform the included ones.
 *
 * @param   array     The array to transform, will not be modified.
 * @param   transform The function applied to every item in the array. Return
 *                    null (to exclude item), or (transformed) item to be
 *                    included.
 * @return  array     The array of items resulting after applying transform to
 *                    each element.
 */
export function mapNotNull(array, transform) {
  return array.reduce((arr, item) => {
    const result = transform(item);
    if (result !== null) {
      arr.push(result);
    }
    return arr;
  }, []);
}
