<template>
  <v-dialog
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    overlay
    content-class="ma-5"
    max-width="500"
  >
    <v-card width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.DELIVERY_NOTE)"
      />
      <div class="d-flex flex-column">
        <img
          class="hero d-block align-self-center"
          alt="Driver"
          :src="imgSrc"
        />
      </div>
      <v-card-title class="dialog-title pa-0">
        {{ titleText }}
      </v-card-title>
      <v-card-text
        class="px-0 py-0 mb-4 mt-2"
        data-id="v-card-text-734839284940"
      >
        <span>{{ bodyText }}</span>
        <span v-if="success" class="font-weight-bold"
          >"{{ deliveryNote }}"</span
        >
      </v-card-text>
      <v-textarea
        v-if="!success"
        class="mb-2"
        bg-color="#fff"
        :placeholder="this.inputPlaceholder"
        maxlength="150"
        :disabled="loading"
        variant="outlined"
        no-resize
        required
        :rules="locationRules"
        v-model="deliveryNote"
        v-on:update:model-value="validate"
      ></v-textarea>
      <v-btn
        v-if="!success"
        :block="true"
        type="submit"
        color="info"
        :disabled="!valid || loading"
        :loading="loading"
        v-on:click.prevent="onSubmit"
        class="bg-primary"
        >{{ btnText }}
      </v-btn>
      <v-btn
        v-else
        :block="true"
        type="submit"
        color="info"
        v-on:click.prevent="onClose"
        class="bg-primary"
      >
        {{ btnText }}
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import dialogs from '@/components/dialogs/dialogs.js';
import {
  ACTION_CUSTOMIZED_DELIVERY,
  ACTION_TYPE_ERROR,
  ACTION_TYPE_INFO,
  DELIVERY_NOTE
} from '@/store/constants.js';
import { getUtmSource } from '@/router/queryParam.js';

const TRANSLATION_KEY = 'customization.deliveryHints';
export default {
  name: 'DeliveryNoteDialog',
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      deliveryNote: '',
      success: false,
      loading: false,
      valid: false,
      locationRules: [
        v => !!v || this.$t('customization.deliveryHints.rules.textRequired')
      ]
    };
  },
  computed: {
    btnText() {
      return !this.success ? this.$t('generic.save') : this.$t('generic.okay');
    },
    titleText() {
      return this.$t(
        `${TRANSLATION_KEY}${this.success ? '.thankYou' : ''}.header`
      );
    },
    bodyText() {
      return this.$t(
        `${TRANSLATION_KEY}${this.success ? '.thankYou.content' : '.description'}`
      );
    },
    inputPlaceholder() {
      return this.$t(`${TRANSLATION_KEY}.inputField`);
    },
    imgSrc() {
      return `/delivery-note${this.success ? '-success' : ''}.svg`;
    },
    dialogs() {
      return dialogs;
    },
    ...mapState(['message', 'trackingData']),
    dialog: {
      get() {
        return this.open;
      }
    }
  },
  methods: {
    ...mapActions(['addCustomizedDelivery', 'toggleDialog']),
    dialogHandler(dialog) {
      this.toggleDialog(dialog);
    },
    onSubmit() {
      this.loading = true;

      const payload = {
        body: {
          type: DELIVERY_NOTE,
          description: this.deliveryNote
        },
        utmSource: getUtmSource(this.$route),
        tenantId: this.$rttI18n.tenantId,
        postcode: this.trackingData.toAddress.postcode,
        trackingId: this.trackingData.trackingId
      };
      this.addCustomizedDelivery(payload);
    },
    onClose() {
      this.dialogHandler(dialogs.DELIVERY_NOTE);

      // Reset dialog state in a timer so the dialog does not show the other state before closing
      setTimeout(() => {
        this.deliveryNote = '';
        this.success = false;
      }, 250);
    },
    validate() {
      this.valid = this.deliveryNote.trim().length > 0;
    }
  },
  watch: {
    message(message) {
      if (
        message.msg === ACTION_CUSTOMIZED_DELIVERY &&
        message.type === ACTION_TYPE_INFO
      ) {
        this.success = true;
        this.loading = false;
      }
      if (message.type === ACTION_TYPE_ERROR) {
        this.loading = false;
        this.success = false;
      }
    }
  }
};
</script>

<style scoped></style>
