import { createApp } from 'vue';
import { createStoreAndLinkHttpService } from '@/store';
import App from '@/pages/index/App';
import HttpService from '@/services/http/HttpService';
import { init } from '@/pages/init.js';

const app = createApp(App);

const store = createStoreAndLinkHttpService(new HttpService());
store.$containerId = 'rtt';

const urlPath = window.location.hash.substring(1);
init(urlPath, app, store, false);
