<script>
import dialogs from '@/components/dialogs/dialogs.js';
import TimeFrameSelectionDialog from '@/components/dialogs/TimeFrameSelectionDialog.vue';
import CustomizeDialog from '@/components/dialogs/CustomizeDialog.vue';
import DeliverToNeighbourDialog from '@/components/dialogs/DeliverToNeighbourDialog.vue';
import DropOffDialog from '@/components/dialogs/DropOffDialog.vue';
import NotificationSubscriptionDialog from '@/components/dialogs/NotificationSubscriptionDialog.vue';
import PushNotificationSubscriptionDialog from '@/components/dialogs/PushNotificationSubscriptionDialog.vue';
import { mapGetters, mapState } from 'vuex';
import ParcelShopDialog from '@/components/dialogs/ParcelShopDialog.vue';
import TrustPilotConsentDialog from '@/components/dialogs/TrustPilotConsentDialog.vue';
import DeliveryNoteDialog from '@/components/dialogs/DeliveryNoteDialog.vue';
import { h } from 'vue';

export default {
  name: 'DialogHandler',
  components: {
    DeliveryNoteDialog,
    TrustPilotConsentDialog,
    CustomizeDialog,
    DeliverToNeighbourDialog,
    DropOffDialog,
    NotificationSubscriptionDialog,
    ParcelShopDialog,
    PushNotificationSubscriptionDialog,
    TimeFrameSelectionDialog
  },
  render() {
    return (
      this.activeDialog &&
      h('div', [
        this.withTrackingData(tData => {
          return [
            this.makeDialog(CustomizeDialog, dialogs.CUSTOMIZE, {
              supportedCustomization: tData.supportedCustomizedDeliveryTypes,
              smsNotification: tData.smsNotification,
              pushNotification: tData.pushNotification
            }),
            this.makeDialog(DeliverToNeighbourDialog, dialogs.NEIGHBOUR),
            this.makeDialog(DropOffDialog, dialogs.DROP_OFF),
            this.makeDialog(
              NotificationSubscriptionDialog,
              dialogs.NOTIFICATION_SUBSCRIPTION
            ),
            this.makeDialog(
              PushNotificationSubscriptionDialog,
              dialogs.PUSH_NOTIFICATION_SUBSCRIPTION
            ),
            this.makeDialog(TimeFrameSelectionDialog, dialogs.DELIVERY_PERIOD)
          ];
        }).concat([
          this.makeDialog(DeliveryNoteDialog, dialogs.DELIVERY_NOTE),
          this.makeDialog(ParcelShopDialog, dialogs.PARCEL_SHOP, {
            parcelShopData: this.dialogPayload
          }),
          this.makeDialog(
            TrustPilotConsentDialog,
            dialogs.TRUST_PILOT_CONSENT_DIALOG
          )
        ])
      ])
    );
  },
  methods: {
    makeDialog(component, dialogName, props) {
      return (
        this.activeDialog === dialogName &&
        h(component, { open: true, ...props })
      );
    },
    withTrackingData(callback) {
      return this.getTrackingData ? callback(this.getTrackingData) : [];
    }
  },
  data() {
    return {
      parcelShopData: undefined,
      dialogs
    };
  },
  computed: {
    ...mapGetters(['getTrackingData']),
    ...mapState(['activeDialog', 'dialogPayload'])
  }
};
</script>

<style scoped></style>
