<template>
  <v-app light class="rttApp" :class="getThemeId">
    <div v-if="getReviewAppId" id="reviewAppBanner">
      Review App {{ getReviewAppId }}
    </div>
    <RttHeader />
    <DialogHandler />
    <v-main>
      <ThemeElementSlot name="Background" />
      <v-container>
        <AlertComponent v-if="!message.modal" :message="message" />
        <router-view></router-view>
      </v-container>
      <ThemeElementSlot name="SubContent" />
    </v-main>
    <RttFooter />
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import RttHeader from '@/components/RttHeader.vue';
import RttFooter from '@/components/RttFooter.vue';
import AlertComponent from '@/components/AlertComponent.vue';
import ThemeElementSlot from '@/components/ThemeElementSlot.vue';
import { setThemeName } from '@/plugins/vuetify.js';
import loadConfigByThemeId, {
  defaultThemeName,
  getThemeIdFromStateAndRoute
} from '@/config/themes.js';
import DialogHandler from '@/components/dialogs/DialogHandler.vue';

export default {
  name: 'App',
  components: {
    DialogHandler,
    AlertComponent,
    RttFooter,
    RttHeader,
    ThemeElementSlot
  },
  computed: {
    ...mapGetters(['getReviewAppId', 'getThemeId']),
    ...mapState(['message', 'trackingData']),
    themeConfig() {
      return loadConfigByThemeId(this.getThemeId);
    }
  },
  methods: {
    ...mapActions(['setThemeId']),
    updateTheme() {
      const themeId =
        getThemeIdFromStateAndRoute(this.$store.state, this.$route) ||
        defaultThemeName();
      setThemeName(this.$vuetify, themeId);
      this.setThemeId(themeId);
    }
  },
  watch: {
    '$route.params.countryOrTenantId'(newCountry, oldCountry) {
      this.updateTheme();
    },
    trackingData(newTrackingData) {
      this.updateTheme();
    }
  }
};
</script>

<style>
.rttApp {
  /* put styles into overrides.scss which are generic for index and widget */
  #reviewAppBanner {
    background: #e0e2f1;
    color: #061ab1;
    padding: 1em;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}
</style>
