<template>
  <v-dialog
    :attach="`#${$store.$containerId} .rttApp`"
    v-model="dialog"
    persistent
    :overlay="true"
    content-class="ma-5"
    max-width="500"
  >
    <v-card v-if="!success" width="100%" class="card_dialog v-col-12">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.NOTIFICATION_SUBSCRIPTION)"
      />
      <v-card-title class="dialog-title">
        {{ $t('customization.notificationSubscription.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2"
        ><div v-html="$t('customization.notificationSubscription.description')"
      /></v-card-text>
      <v-form :ref="notificationSubscriptionForm" v-model="valid">
        <div class="v-row ma-0">
          <PhoneNumberCountryPrefixComponent
            v-if="this.open"
            @setPrefix="setPrefix"
            class="v-col-4"
          />
          <v-text-field
            v-model="phoneNumber"
            bg-color="#fff"
            class="v-col-8 phone-number"
            variant="outlined"
            ref="phoneNumberInput"
            required
            :label="$t('generic.mobileNumber')"
            :disabled="!prefix"
            :placeholder="
              $t(
                'customization.notificationSubscription.phoneNumber.placeholder'
              )
            "
            v-on:keyup="setMobileNumber"
            type="tel"
          ></v-text-field>
          <v-card-text class="px-0 pt-1 pb-2 text-footnote">
            <i18n-t
              keypath="customization.notificationSubscription.privacyProtectionText"
              tag="span"
            >
              <template v-slot:link>
                <a :href="getPrivacyLink()" target="_blank">{{
                  $t('privacy.name')
                }}</a>
              </template>
            </i18n-t>
          </v-card-text>
        </div>
        <div>
          <v-btn
            :block="true"
            type="submit"
            variant="flat"
            v-on:click.prevent="prepareAddNotificationSubscriptionData"
            :disabled="
              !valid || !phoneNumber || phoneNumber.length < 3 || !prefix
            "
            class="bg-info"
            color="info"
            >{{ $t('generic.save') }}</v-btn
          >
        </div>
      </v-form>
    </v-card>

    <v-card v-if="success" width="100%" class="v-col-12 px-4 py-5 text-center">
      <v-btn
        icon="$close"
        variant="text"
        density="comfortable"
        class="close-button float-right"
        v-on:click="dialogHandler(dialogs.NOTIFICATION_SUBSCRIPTION)"
      />
      <v-card-title class="dialog-title pa-0 text-center">
        {{ $t('customization.notificationSubscription.success.header') }}
      </v-card-title>
      <v-card-text class="px-0 py-2">
        {{ $t('customization.notificationSubscription.success.description') }}
        <br />
        {{ fullPhoneNumber }}
      </v-card-text>
      <div>
        <v-form>
          <v-btn
            type="submit"
            variant="outlined"
            color="anchor"
            v-on:click.prevent="
              dialogHandler(dialogs.NOTIFICATION_SUBSCRIPTION)
            "
            >{{ $t('generic.okay') }}</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import PhoneNumberCountryPrefixComponent from '@/components/PhoneNumberCountryPrefixComponent';
import {
  ACTION_NOTIFICATION_SUBSCRIPTION,
  ACTION_TYPE_ERROR,
  ACTION_TYPE_INFO
} from '@/store/constants';
import dialogs from '@/components/dialogs/dialogs.js';

const phoneNoFormatRegEx =
  /(\+)(1|7|20|27|30|31|32|33|34|36|39|40|41|43|44|45|46|47|48|49|51|52|53|54|55|56|57|58|60|61|62|63|64|65|66|81|82|84|86|90|91|92|93|94|95|98|211|212|213|216|218|220|221|222|223|224|225|226|227|228|229|230|231|232|233|234|235|236|237|238|239|240|241|242|243|244|245|246|247|248|249|250|251|252|253|254|255|256|257|258|260|261|262|263|264|265|266|267|268|269|290|291|297|298|299|350|351|352|353|354|355|356|357|358|359|370|371|372|373|374|375|376|377|378|380|381|382|383|385|386|387|389|420|421|423|500|501|502|503|504|505|506|507|508|509|590|591|592|593|594|595|596|597|598|599|670|672|673|674|675|676|677|678|679|680|681|682|683|685|686|687|688|689|690|691|692|850|852|853|855|856|880|886|960|961|962|963|964|965|966|967|968|970|971|972|973|974|975|976|977|992|993|994|995|996|998|1242|1246|1264|1268|1284|1340|1345|1441|1473|1649|1664|1670|1671|1684|1721|1758|1767|1784|1787|1809|1829|1849|1868|1869|1876|1939|4779|5999|3906698)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{7,20}$/;

export default {
  name: 'NotificationSubscriptionDialog',
  components: { PhoneNumberCountryPrefixComponent },
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      fullPhoneNumber: null,
      loading: false,
      notificationSubscriptionForm: null,
      prefix: null,
      phoneNumber: null,
      success: false,
      valid: false,
      dialogs
    };
  },
  computed: {
    ...mapState(['message', 'trackingData']),
    dialog: {
      get() {
        return this.open;
      }
    }
  },
  methods: {
    ...mapActions(['addNotificationSubscription', 'toggleDialog']),
    getPrivacyLink() {
      return this.$rttI18n.tenantSpecificWithFallback(
        'privacy.link',
        'glsGroup.privacy.link'
      );
    },
    prepareAddNotificationSubscriptionData() {
      this.loading = true;
      const payload = {
        body: {
          phone: this.fullPhoneNumber,
          language: this.$rttI18n.locale,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        tenantId: this.$rttI18n.tenantId,
        postcode: this.trackingData.toAddress.postcode,
        trackingId: this.trackingData.trackingId
      };
      this.addNotificationSubscription(payload);
    },
    dialogHandler(dialog) {
      this.resetDialog();
      this.toggleDialog(dialog);
    },
    resetDialog() {
      this.fullPhoneNumber = null;
      this.phoneNumber = null;
      this.prefix = null;
    },
    setMobileNumber() {
      if (this.prefix && this.phoneNumber) {
        this.fullPhoneNumber = `${this.prefix}${this.phoneNumber.replace(
          /^0/,
          ''
        )}`;
        this.valid = this.hasPhoneNumberValidFormat(this.fullPhoneNumber);
      }
    },
    setPrefix(prefix) {
      this.prefix = prefix ? prefix.value : null;
      if (!prefix) {
        this.fullPhoneNumber = null;
        this.phoneNumber = null;
      }
      this.$nextTick(() => this.$refs.phoneNumberInput.focus());
    },
    /**
     * Checks if the phone number has a valid format, returns a Boolean
     * TODO: Check phone number regEx for better solution, also it looks like that it contains some legacy numbers for testing
     * The tenantId prefixes where updated 2021-11-11, from https://en.wikipedia.org/wiki/List_of_mobile_telephone_prefixes_by_country
     * @param phoneNumber
     * @returns Boolean
     */
    hasPhoneNumberValidFormat(phoneNumber) {
      if (
        !phoneNumber ||
        phoneNumber.substring(1, phoneNumber.length).includes('+')
      )
        return false;

      return phoneNoFormatRegEx.test(phoneNumber);
    }
  },
  watch: {
    message() {
      if (
        this.message.msg === ACTION_NOTIFICATION_SUBSCRIPTION &&
        this.message.type === ACTION_TYPE_INFO
      ) {
        this.loading = false;
        this.success = true;
      }
      if (this.message.type === ACTION_TYPE_ERROR) {
        this.loading = false;
        this.success = false;
      }
    },
    open() {
      if (this.open) {
        this.success = false;
        const phone = this.trackingData.smsNotification?.phone;
        if (phone) {
          const matches = phoneNoFormatRegEx.exec(phone);
          this.prefix = `+${matches[2]}`;
          this.phoneNumber = phone.substring(matches[2].length + 1);
        }
      }
      if (this.open && this.prefix) {
        this.$nextTick(() => this.$refs.phoneNumberInput.focus());
      }
    }
  },
  mounted() {
    if (this.prefix) {
      this.$nextTick(() => this.$refs.phoneNumberInput.focus());
    }
  }
};
</script>

<style scoped>
.phone-number {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  width: 80%;
}
</style>
